<template>
  <div class="article">
    <div class="sale_policy">
      <p>
        <b
          >1.预计退款金额=实付金额-正常扣除金额-教材费用-教材运费，每项扣除金额说明如下：</b
        >
      </p>

      <p>
        1.1
        正常扣除费用为您购买的虚拟商品（包含录播课、直播课等），是按照支付时间长短扣除不同比例支付金额的梯度计算公式计算出来的扣款金额，计算公式如下：
      </p>
      <br />
      <table>
        <tr>
          <td>支付时间t</td>
          <td>扣除比例</td>
        </tr>
        <tr>
          <td>t &lt;= 3小时</td>
          <td>0</td>
        </tr>
        <tr>
          <td>3小时&lt;t&lt;=24小时</td>
          <td>20%</td>
        </tr>
        <tr>
          <td>24小时&lt;t&lt;= 48小时</td>
          <td>50%</td>
        </tr>
        <tr>
          <td>48小时&lt;t&lt;=72小时</td>
          <td>80%</td>
        </tr>
        <tr>
          <td>t>72小时</td>
          <td>不予退款</td>
        </tr>
      </table>
      <br />
      <p>
        1.2教材费用为单本教材当前售价，教材运费统一为10元,未发货的教材运费不扣除。
      </p>
      <p>
        <b
          >2.若您购买的商品中包含图书且已经发货，扣除项默认为梯度扣除费用和教材运费，教材费暂不扣除。若后续您将图书寄回，将按照不扣除教材费进行退款，若您未将图书寄回，会再将教材费扣除。</b
        >
      </p>

      <p>其余说明：</p>
      <p>
        1)
        退款只支持微信、支付宝方式支付的订单，通过学习卡充值、激活码支付的不予退款，题库类商品购买后不支持退款。
      </p>
      <p>
        2)
        通过苹果设备购买，因苹果平台规则设置问题，无法进行退款，请您谨慎下单。
      </p>
      <p>4) 退款申请提交后请保持联系方式畅通，我们会尽快与您确认信息。</p>
      <p>5) 参与活动特价（包括但不限于团购、秒杀）购买的课程不予退款。</p>
      <p>
        6)
        申请退款后并完成退款流程后，退款将会在15个工作日内原路退回至您的支付账户。
      </p>

      <p>
        附：库课网校视频课程观看包含有效期限，请您购买后在有效期限内进行学习，因个人原因导致课程过期，库课网校不提供退款服务，敬请谅解。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
/* p{
    margin-top: 0;
    margin-bottom: 0;
} */
.article {
  width: 100%;
  padding: 0 0.3rem 0.4rem;
  box-sizing: border-box;
  font-size: 0.4rem;
  line-height: 0.6rem;
  color: #494949;
}
table {
  width: 100%;
  border: 1px solid #262626;
  margin: 0 auto;
  font-size: 0.2rem;
  color: #262626;
  border-collapse: collapse;
  border-spacing: 0;
}
table td {
  border: 1px solid #262626;
  text-align: center;
  font-weight: bold;
}
</style>
>
